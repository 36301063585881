import React, { useCallback, useState } from 'react';
import api from '../../../services/api';
import {
  Container,
  Form,
  CheckboxLabel,
  Button,
  PrivacyText,
  Input,
  InputContainer,
  TooltipError,
} from './styles';

interface Props {
  resultId: number;
  code: string;
  imageBase64: string | null;
  captureBubbleTreeImage: () => Promise<string | null>;
}

const TsoSelbtestResults: React.FC<Props> = ({
  resultId,
  code,
  imageBase64,
  captureBubbleTreeImage,
}): JSX.Element => {
  const [email, setEmail] = useState('');
  const [subscribeToTips, setSubscribeToTips] = useState(false);
  const [error, setError] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [invalidMailError, setInvalidMailError] = useState('');
  const [localImage, setLocalImage] = useState<string | null>(imageBase64);

  const validateEmail = (inputEmail: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(inputEmail);
  };

  const handleButtonSendMail = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();

      if (!validateEmail(email)) {
        setInvalidMailError('Bitte geben Sie eine gültige E-Mail-Adresse ein.');
        return;
      }

      setInvalidMailError('');

      // Capturar imagem somente se ainda não estiver capturada
      let imageToSend = localImage;
      if (!localImage) {
        imageToSend = await captureBubbleTreeImage();
        setLocalImage(imageToSend);
      }

      if (!imageToSend) {
        setError(
          'Fehler beim Generieren des Bildes. Bitte versuchen Sie es erneut.',
        );
        return;
      }

      const respondent = {
        email,
        resultId,
        code,
        reportAcceptance: subscribeToTips,
        newsletterAcceptance: false,
        imageChartResults: imageToSend.split(',')[1]?.trim(),
      };

      try {
        await api.post('/respondents', respondent);
        setSubmitted(true);
      } catch (err) {
        console.error('Erro ao enviar e-mail:', err);
        setError('Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.');
      }
    },
    [
      captureBubbleTreeImage,
      code,
      email,
      localImage,
      resultId,
      subscribeToTips,
    ],
  );

  return (
    <Container>
      {!submitted && (
        <div style={{ marginBottom: '20px', marginTop: '-60px' }}>
          <h1>
            Gib deine <span style={{ color: '#980000' }}>E-Mail-Adresse</span>{' '}
            ein, und wir senden dir dein persönliches Ergebnis direkt in dein
            Postfach:
          </h1>
        </div>
      )}
      {!submitted ? (
        <Form onSubmit={handleButtonSendMail}>
          <InputContainer>
            <Input
              type="email"
              placeholder="E-Mail-Adresse hier einfügen"
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
            {invalidMailError && (
              <TooltipError left="40%">
                <span>{invalidMailError}</span>
              </TooltipError>
            )}
          </InputContainer>
          {error && <span className="error">{error}</span>}

          <CheckboxLabel>
            <input
              type="checkbox"
              checked={subscribeToTips}
              onChange={() => setSubscribeToTips(!subscribeToTips)}
            />
            Ja, ich möchte hilfreiche Tipps und Informationen zu
            Kundenorientierung und Veranstaltungen per E-Mail erhalten. Ich
            weiß, dass eine Abmeldung jederzeit möglich ist.
          </CheckboxLabel>

          <PrivacyText>
            Durch die Übermittlung meiner personenbezogenen Daten willige ich
            ein, dass meine Daten in Übereinstimmung mit den{' '}
            <a
              href="https://www.top-service-oesterreich.at/datenschutzerklaerung/"
              target="_blank"
              rel="noreferrer"
            >
              <u>
                <b>Datenschutzrichtlinien</b>
              </u>
            </a>{' '}
            erhoben, verarbeitet und gespeichert werden.
          </PrivacyText>

          <Button type="submit" disabled={!subscribeToTips}>
            Ergebnis erhalten
          </Button>
        </Form>
      ) : (
        <div
          style={{
            marginBottom: '20px',
            marginTop: '-50px',
            marginLeft: '90px',
          }}
        >
          <h2>Vielen Dank! Dein Ergebnis wird in Kürze gesendet.</h2>
        </div>
      )}
    </Container>
  );
};

export default TsoSelbtestResults;
