/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-continue */

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Language {
  languageId: number;
  name: string;
  pagePrevText: string;
  pageNextText: string;
  completeText: string;
  progressText: string;
  requiredErrorText: string;
  placeholderTextQuestion: string;
  requiredAllrowsErrorText: string;
  value: number;
  label: string;
}
const getEncodedSurvey = (text: string, language?: Language | null): any => {
  const placeHolderTexQuestion =
    language?.placeholderTextQuestion ??
    'Tippen Sie Ihre Antwort bitte hier ein:';

  const surveyJsonStructure = JSON.parse(text);

  if (!surveyJsonStructure.pages || surveyJsonStructure.pages.length === 0) {
    console.info(
      'Skipping encode, survey has no pages or is in an intermediate state.',
      surveyJsonStructure,
    );
    return surveyJsonStructure;
  }
  const surveyToSave: any = { ...surveyJsonStructure }; // clone needed to loop the original and change the copy

  // check if need split pages
  const uniqueDesignerPage: any = { ...surveyJsonStructure.pages[0] };
  const pageBeakers =
    uniqueDesignerPage &&
    uniqueDesignerPage.elements?.filter((e: any) => {
      return e?.type === 'pagebreak';
    });

  // end check if need split pages

  // create pages array with the exact lenght
  const pages: object[] = [pageBeakers?.length];
  uniqueDesignerPage.elements = [];

  // set the first page preserving first page properties
  pages[0] = { ...uniqueDesignerPage };

  surveyToSave.pages = pages; // clean clone elements to receive new pages with it splited

  // split pages
  let pageIndex = 1;
  for (let i = 0; i < surveyJsonStructure.pages[0]?.elements?.length; i += 1) {
    let element = surveyJsonStructure.pages[0]?.elements[i];

    if (element.internalType === 'Text') {
      element = {
        ...element,
        placeHolder: element.placeholder
          ? element.placeholder
          : placeHolderTexQuestion,
      };
    }

    if (
      element.internalType === 'Scale' ||
      element.internalType === 'SelectOne' ||
      element.internalType === 'SelectMultiple'
    ) {
      element = {
        ...element,
        choices: element.choices.map((choice: any) => {
          if (isNaN(choice)) {
            return choice;
          }

          const newChoice = {
            value: choice,
            text: `${choice}‎`,
          };
          return newChoice;
        }),
        rateValues: element.choices.map((choice: any) => {
          if (isNaN(choice)) {
            return choice;
          }

          const newChoice = {
            value: choice,
            text: `${choice}‎`,
          };
          return newChoice;
        }),
      };
    }

    if (element?.matrixId && element.rows) {
      element.rows = element?.rows?.map((row: any) => {
        return {
          questionId: row.questionId ?? row.questionIdMatrix,
          externalId: row.externalId,
          questionIdMatrix: row.questionId ?? row?.questionIdMatrix,
          visibleIf: row.rowVisibleIf,
          value: row.questionId ?? row?.questionIdMatrix,
          text: row.text,
          numberOfRowsToSplit: row.numberOfRowsToSplit,
          splitMatrix: row.splitMatrix,
          totalOfRows: row?.numberOfRowsToSplit,
          numberOfGroups: row.numberOfGroups,
          numberOfGroupsToInsertPageBreakAfter:
            row.numberOfGroupsToInsertPageBreakAfter,
        };
      });
    }

    if (element.type === 'pagebreak') {
      pageIndex += 1;
      surveyToSave.pages.push({
        name: `page${pageIndex}`,
        title: element.title,
        elements: [],
      });
      continue;
    }

    surveyToSave.pages[pageIndex - 1].elements?.push(element);
  }

  return surveyToSave;
};

export default getEncodedSurvey;
