/* eslint-disable no-underscore-dangle */
/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';

import Select from 'react-select';
import SelectForm from '../../../../components/SelectForm';
import api from '../../../../services/api';

import { useToast } from '../../../../hooks/toast';
import getValidationErrors from '../../../../utils/getValidationErrors';

import Input from '../../../../components/FormInput';
import Button from '../../../../components/Button';

import { Container } from './styles';
import BackButton from '../../../../components/BackButton';
import TextArea from '../../../../components/TextAreaEb';

interface Logo {
  id: number;
  filePath: string;
  label?: string;
}

interface Language {
  languageId: number;
  name: string;
  pagePrevText: string;
  pageNextText: string;
  completeText: string;
  progressText: string;
  requiredErrorText: string;
  placeholderTextQuestion: string;
  requiredAllrowsErrorText: string;
  value: number;
  label: string;
}

interface State {
  languageId: number;
  surveyTranslationId: number;
  surveyId: number;
  survey: any;
}

const EditSurveyTranslation: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const history = useHistory();
  const { state } = useLocation<State>();
  const { addToast } = useToast();
  const [logos, setLogos] = useState<Logo[]>();
  const [selectedLogo, setSelectedLogo] = useState<Logo | null>();
  const [surveyTranslation, setSurveyTranslation] = useState<any>();
  const [selectedLanguage, setSelectedLanguage] = useState<Language | null>();
  const [survey, setSurvey] = useState<any>();
  const [loading, setLoading] = useState(false);
  const returnFieldName = useCallback(elementName => {
    const regexReplace = new RegExp('[^0-9a-zA-Z]+', 'g');
    const fieldName = elementName.replace(regexReplace, '');
    return fieldName;
  }, []);

  useEffect(() => {
    setLoading(true);

    const fetchData = async (): Promise<void> => {
      await api
        .get(`/surveyTranslations/${state?.surveyTranslationId}`)
        .then(async responseSurvey => {
          const surveyTranslationJson = responseSurvey.data;

          setSurveyTranslation(surveyTranslationJson);

          await api
            .get(`languages/${surveyTranslationJson.languageId}`)
            .then(async response => {
              setSelectedLanguage({
                ...response.data,
                value: response.data.languageId,
                label: response.data.name,
              });
            });
        });
    };
    fetchData();
    setLoading(false);
  }, [state.languageId, state.surveyId, state?.surveyTranslationId]);

  /// GET LOGOS
  useEffect(() => {
    api.get('logos').then(response => {
      // setServer(`${response.request?.responseURL.replace('/logos', '/files')}`);

      const logosDB: Logo[] = response.data.map((logo: any) => {
        return {
          value: logo.logoId,
          label: logo.originalname,
          filePath: `${response.request?.responseURL.replace(
            '/logos',
            '/files',
          )}/${logo?.filename}`,
        };
      });
      setLogos(logosDB);

      if (surveyTranslation?.logo) {
        const logoSurveyTranslation = logosDB.find(
          (x: any) => x.value === surveyTranslation.logo.id,
        );

        if (logoSurveyTranslation) {
          formRef.current?.setFieldValue('logoId', logoSurveyTranslation);
        }
      }
    });
  }, [surveyTranslation]);

  useEffect(() => {
    api
      .get(`/surveys/${state?.surveyTranslationId}`)
      .then(async responseSurvey => {
        const surveyDB = responseSurvey.data;
        setSurvey(surveyDB);
      });
  }, [state?.surveyTranslationId]);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        const surveyTranslationToUpdate = surveyTranslation;
        surveyTranslationToUpdate.translation.title = data.title;

        const pages = surveyTranslationToUpdate?.translation?.pages?.map(
          (page: any) => {
            const elements = page?.elements?.map((element: any) => {
              let elementUpdate: any = element;
              let propertyFiledName: string | undefined = '';
              if (element.type === `matrix` || element.type === 'html') {
                if (element.type === `matrix`) {
                  const regexReplace = new RegExp('[^0-9a-zA-Z]+', 'g');
                  const elementNameToFind = element?.name.replace(
                    regexReplace,
                    '',
                  );

                  propertyFiledName = Object.keys(data).find(
                    (key: any) => `${key}` == elementNameToFind,
                  );
                } else {
                  propertyFiledName = Object.keys(data).find(
                    (key: any) => `${key}` == element.name,
                  );
                }

                const propertyToUpdate = [
                  element.type === 'matrix' ? `title` : 'html',
                ];

                elementUpdate = {
                  ...element,
                  [`${propertyToUpdate}`]: data[`${propertyFiledName}`],
                };

                return elementUpdate;
              }
              if (element.questionId && element.description) {
                let questionDescription = '';

                if (element?.questionId?.toString().includes('.')) {
                  // need to replace . by _  because the name of the field in the form does not consider the string after the.
                  const alternativIdFieldNameToFind = `${element.questionId
                    .toString()
                    .replace('.', '_')}`;

                  const alternativeIdFormData = Object.keys(data).find(
                    (key: any) => key == alternativIdFieldNameToFind,
                  );

                  questionDescription = data[`${alternativeIdFormData}`];
                } else {
                  const questionIdFormData = Object.keys(data).find(
                    (key: any) => key == element.questionId,
                  );

                  if (questionIdFormData == element.questionId) {
                    questionDescription = data[`${questionIdFormData}`];
                  }
                }

                elementUpdate = {
                  ...element,
                  description: questionDescription || '',
                };

                return elementUpdate;
              }

              return element;
            });
            const pageToUpdate = { ...page, elements };

            return pageToUpdate;
          },
        );

        const logoSelect = {
          id: selectedLogo?.id,
          filePath: selectedLogo?.filePath,
        };

        const newSurveyTranslation = {
          translation: {
            ...surveyTranslation?.translation,
            title: data.title,
            description: data.surveyDescription,
            pages,
          },
          logo: logoSelect,
        };

        await api.put(
          `/surveyTranslations/${surveyTranslation.surveyTranslationId}`,
          newSurveyTranslation,
        );

        addToast({
          type: 'success',
          title: 'Success',
          description: 'Survey Translation was saved successfully!',
        });

        history.push({
          pathname: `/surveys/${state.surveyId}/surveyTranslations`,
          state: { surveyId: state.surveyId, survey: state.survey },
        });
      } catch (err) {
        console.log('error', err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }
        addToast({
          type: 'error',
          title: 'Registration Error',
          description:
            'An error occurred while creating survey translation, please try again.',
        });
      }
    },
    [
      addToast,
      history,
      selectedLogo,
      state.survey,
      state.surveyId,
      surveyTranslation,
    ],
  );

  const handleLogoChange = useCallback(x => {
    const logoSelected = {
      id: x.value,
      filePath: x.filePath,
      label: x.label,
    };
    setSelectedLogo(logoSelected);
  }, []);

  return (
    <Container>
      <BackButton />
      <Form ref={formRef} onSubmit={handleSubmit}>
        <h1>Edit Survey Translation</h1>

        <p>Language</p>
        <Select options={[]} value={selectedLanguage} isDisabled={true} />
        <br />
        <p>Logo:</p>
        <SelectForm
          name="logoId"
          options={logos}
          onChange={e => handleLogoChange(e)}
          formatOptionLabel={(logo: any) => (
            <div className="logo-option">
              <img src={logo.filePath} alt="logo-image" />
              <span>{logo.label}</span>
            </div>
          )}
        />

        <div style={{ marginTop: 20 }}>
          <p>Survey Title: {survey?.title}</p>

          <Input
            type="text"
            name="title"
            defaultValue={surveyTranslation?.translation?.title}
            placeholder="Enter translation for survey title"
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <p>Survey Description: {survey?.description}</p>

          <Input
            type="text"
            name="surveyDescription"
            placeholder="Enter translation for survey description"
            defaultValue={surveyTranslation?.translation?.description}
          />
        </div>
        {surveyTranslation &&
          surveyTranslation?.translation?.pages?.map((page: any) =>
            page?.elements?.map((element: any, i: number) =>
              element?.type === 'matrix' &&
              element?.titleLocation !== 'hidden' ? (
                <div key={i} style={{ marginTop: 30 }}>
                  <p>Matrix Title: {element.title}</p>
                  <Input
                    type="text"
                    name={returnFieldName(element.name)}
                    defaultValue={element.title}
                  />
                </div>
              ) : element?.type === 'html' ? (
                <div key={i} style={{ marginTop: 30 }}>
                  <p>
                    HTML Name: {element.name} {`>>`} Page: {i}
                  </p>
                  <TextArea name={element.name} defaultValue={element.html} />
                </div>
              ) : element?.description ? (
                <div key={i} style={{ marginTop: 30 }}>
                  <p>
                    Description of question{' '}
                    <strong>{element.questionId}</strong> at Page: {i + 1}
                  </p>
                  <p>Original description: {element.description}</p>
                  <TextArea
                    name={element.questionId.toString().replace('.', '_')}
                    defaultValue={element.description}
                  />
                </div>
              ) : (
                <div key={i}></div>
              ),
            ),
          )}

        <Button type="submit" width="200px">
          Save
        </Button>
      </Form>
    </Container>
  );
};
export default EditSurveyTranslation;
