/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import html2canvas from 'html2canvas';

import canvasConfetti from 'canvas-confetti';

import { useRouteMatch } from 'react-router-dom';

import { FiAlertTriangle } from 'react-icons/fi';

import SurveyPage from '../../components/SurveyComponent/SurveyJson/Survey';
import StartPage from './Start';

import api from '../../services/api';

import { useQuery } from '../../hooks/queryString';
import { useToast } from '../../hooks/toast';

import {
  AlertMessage,
  Container,
  Footer,
  Header,
  HeaderImage,
  HiddenBubbleTree,
  RootDivFonts,
} from './styles';

import EnumChartType from '../../utils/enums/EnumChartType';
import EnumQuestionType from '../../utils/enums/EnumQuestionType';
import { getElementsWithoutId } from '../../utils/getElementsWithoutId';
import { getSplittedMatrix } from '../../utils/getSplittedMatrix';

import RadarContainer from '../../components/Charts/Radar/RadarContainer';

import BubbleTreeContainer from '../../components/Charts/BubbleTree/BubbleTreeContainer';
import OverlayBox from '../../components/OverlayBox';
import SelectLanguage from './SelectLanguage';
import TsoSelbtestResults from '../../components/SelbtestResults/TsoSelbtestResults';

let contactUsMessage =
  'Es liegt ein Fehler vor? Dann melden Sie sich bitte bei office@emotion-banking.at';

interface MessageWarnings {
  invalidCode: string;
  lockedAccess: string;
  completedSurvey: string;
  invalidUrl: string;
}

interface SurveyParams {
  accessObjectId: string;
  accessId: string;
  code: string;
}

interface Logo {
  logoId: number;
  filePath: string;
}

interface SurveyText {
  surveyTextId: number;
  text: string;
  isStartText: boolean;
}

interface Header {
  filePath?: string;
  id?: number;
}

interface Background {
  filePath?: string;
  id?: number;
}
interface Style {
  urlFavicon: string;
  browserTitle: string;
  buttonsColor: string;
  overlayBoxColor: string;
  urlLogo: string;
  urlRadarChart: string;
  heightLogo: string;
  headerColor: string;
  header: Header;
  background: Background;
  hideProgressText: boolean;
  showConfettiRain: boolean;
}
interface Survey {
  idSurvey: number;
  isTemplate: boolean;
  description: string | object;
  locale: string;
  name: string;
  title: string;
  showTitle: boolean;
  pages: object[];
  projectId: number;
  subGroup: string;
  logo: Logo;
  startText: SurveyText;
  finalText: SurveyText;
  style: Style;
  // completedHtml: SurveyText;
  gdprId: number;
  defaultLanguageId: number;
  languages: any;
}

interface Access {
  accessId: number;
  isLocked: boolean;
  segments: string[];
  survey: Survey;
}

interface Result {
  accessId: number | undefined;
  userGroup: string;
  surveyId: number | undefined;
  testMode: boolean;
  questionResults: QuestionResult[];
  currentPageNo: number;
  isCompleted: boolean;
  languageId: number | null | undefined;
}

interface MetadataContent {
  categories: string[];
  language: string;
  original_text: string;
  sentiment: string;
  translated: string;
  user_group: string;
}

interface QuestionResult {
  questionId: number;
  questionText: string;
  questionName: string;
  questionType: string;
  optionText: string;
  value: string;
  score?: number;
  matrixTitle?: string;
  matrixId?: number;
  alternativeId?: number;
  metadata?: MetadataContent;
}

// let surveysTso = [60];

// if (
//   process &&
//   process.env &&
//   process.env.NODE_ENV &&
//   process.env.NODE_ENV === 'development'
// ) {
//   surveysTso = [59];
// }
// const surveysTsoList = [59, 60, 61, 62, 64];
const surveysSpiritHoch3List = [55, 53, 56];

const Survey: React.FC = () => {
  // „Was? Du willst uns schon verlassen? Bleib´ dran, es kommen noch interessante Fragen 😊“
  const query = useQuery();
  const { addToast } = useToast();
  // const position = useMousePosition();
  const bubbleTreeRef = useRef<HTMLDivElement | null>(null); // Crie um ref para o componente BubbleTreeContainer
  const [bubbleTreeImage, setBubbleTreeImage] = useState<string | null>(null);

  const [access, setAccess] = useState<Access>();
  const [surveyVisible, setSurveyVisible] = useState(false);
  const { params } = useRouteMatch<SurveyParams>();
  const [localStorageName] = useState<string>(
    params.code
      ? `@Victor:results-${params.accessId}-${params.code}`
      : `@Victor:results-${params.accessId}`,
  );
  const [loadData, setLoadData] = useState<any>();
  const [currentPageNo, setCurrentPageNo] = useState<number>();
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [showChart, setShowChart] = useState<string | null>();
  const [showOverlay, setShowOverlay] = useState(false);
  const [returnedResultId, setReturnedResultId] = useState(0);
  const [returnedSurveyId, setReturnedSurveyId] = useState(0);
  const [returnedCode, setReturnedCode] = useState('');
  const [backgroundColor, setBackGroundColor] = useState('');
  const [buttonColor, setButtonColor] = useState('');
  const [hideProgressText, setHideProgressState] = useState(false);
  const [headerColor, setHeaderColor] = useState('');
  const [heightLogo, setHeightLogo] = useState('');
  const [urlRadarChart, setUrlRadarChart] = useState('');
  const [urlHeaderImage, setUrlHeaderImage] = useState('');
  const [urlBackgroundImage, setUrlBackgroundImage] = useState('');
  const [showFinalMessage, setShowFinalMessage] = useState<boolean>(false);

  const [currentPageAnswering, setCurrentPageAnswering] = useState(1);
  const [languageId, setLanguageId] = useState<number | null>();
  const [languageCode, setLanguageCode] = useState<string>('de');
  const [buttonFortfahrenName, setButtonFortfahrenName] =
    useState<string>('Fortfahren');

  const [buttonMehrInformation, setButtonMehrInformation] =
    useState<string>('Mehr Information');

  const [footnoteGDPR, setFootnoteGDPR] = useState<string>('');

  const [showSelectLanguageFirst, setShowSelectLanguageFirst] =
    useState<boolean>(false);

  const [warningMessages, setWarningMessages] = useState<MessageWarnings>({
    invalidCode: 'Dieser Teilnahmecode ist ungültig.',
    lockedAccess: 'Diese Umfrage ist nicht mehr aktiv.',
    completedSurvey:
      'Sie haben bereits an der Umfrage teilgenommen. Vielen Dank für Ihre Teilnahme.',
    invalidUrl: 'Diese Umfrage ist nicht mehr aktiv.',
  });

  const [gdprId, setGdprId] = useState<number>(-1);
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  const getWarningsMessage = useCallback(
    (action: string): string => {
      switch (action) {
        case 'invalidCode':
          return warningMessages.invalidCode;

        case 'lockedAccess':
          return warningMessages.lockedAccess;

        case 'completedSurvey':
          return warningMessages.completedSurvey;

        default:
          break;
      }
      return '';
    },
    [
      warningMessages.completedSurvey,
      warningMessages.invalidCode,
      warningMessages.lockedAccess,
    ],
  );

  const captureBubbleTreeImage = useCallback(async (): Promise<
    string | null
  > => {
    if (bubbleTreeRef.current) {
      try {
        const canvas = await html2canvas(bubbleTreeRef.current);
        const imageBase64 = canvas.toDataURL('image/png');
        setPreviewImage(imageBase64); // Armazena a imagem capturada para exibição
        return imageBase64;
      } catch (error) {
        console.error('Erro ao capturar imagem:', error);
        return null;
      }
    }
    console.warn('Componente BubbleTreeContainer não encontrado');
    return null;
  }, []);

  // if url has the parameter 'resp' the system must to redirect the user
  useEffect(() => {
    const codeRespParameter = query.get('resp');
    if (codeRespParameter) {
      const urlWithoutParameters = window.location.href.split('?')[0];
      const parameters = window.location.href.split('?')[1];

      const cleaningParameter = parameters.replace(`&resp`, 'resp');
      const parametersWithoutResp = cleaningParameter.replace(
        `resp=${codeRespParameter}`,
        '',
      );

      const finalUrl = `${urlWithoutParameters}/${codeRespParameter}`; // ?${parametersWithoutResp} `;

      if (parametersWithoutResp) {
        window.location.replace(`${finalUrl}?${parametersWithoutResp}`);
      } else {
        window.location.replace(finalUrl);
      }
    }
  }, [query]);

  // Change the results before save it in the database
  const modifySurveyResults = useCallback(
    async (survey: {
      data: any;
      getQuestionByValueName: (arg0: string) => any;
    }) => {
      const resultData: QuestionResult[] = [];

      for (const key in survey.data) {
        const question = survey.getQuestionByValueName(key);

        if (question) {
          let optionTextAnswered: any;

          if (Array.isArray(question.value)) {
            optionTextAnswered = question.value.map((answer: any) => {
              return {
                value: answer,
                text: question.choices.find((i: any) => i.value === answer)
                  ?.text,
              };
            });
          } else {
            optionTextAnswered =
              question.choices?.find((i: any) => i.value === question.value)
                ?.text ?? '';
          }

          if (!question.hasRows) {
            const item: QuestionResult = {
              questionId: parseInt(question.questionId, 10),
              questionText: question.title,
              questionName: key,
              questionType: question.internalType.replace(/\s/g, ''),
              optionText: optionTextAnswered ?? '',
              value: question.value === 'none' ? -99 : question.value,
              score:
                question.internalType === EnumQuestionType.Scale &&
                question.value !== 'none'
                  ? (question.choices.length - question.value) * 25
                  : -99,
            };

            resultData.push(item);
          } else {
            question.rows.forEach((row: any) => {
              if (question.value[row.value]) {
                const item: QuestionResult = {
                  questionId: parseInt(row.value, 10),
                  questionText: row.text,
                  questionName: key,
                  questionType: EnumQuestionType.Scale,
                  matrixTitle: question.title,
                  matrixId: question.matrixId,
                  alternativeId: row.value,
                  optionText:
                    question.columns.find(
                      (i: any) => i.value === question.value[row.value],
                    ).text ?? 'option text not found',
                  value:
                    question.value[row.value] === 'none'
                      ? -99
                      : question.value[row.value],
                  score:
                    question.value !== 'none'
                      ? (question.columns.length - question.value[row.value]) *
                        25
                      : -99,
                };

                resultData.push(item);
              }
            });
          }
        }
      }

      return resultData;
    },
    [],
  );

  // Fetch the result in the database and return in the format to be loaded in surveyjs
  const modifyStoredResults = useCallback(
    (questionResults: QuestionResult[]) => {
      const mountLoadData: any = {};

      questionResults.forEach((questionResult: QuestionResult) => {
        if (questionResult.matrixTitle) {
          if (questionResult.alternativeId) {
            if (mountLoadData[questionResult.questionName]) {
              mountLoadData[questionResult.questionName] = {
                ...mountLoadData[questionResult.questionName],
                [questionResult.alternativeId]: questionResult.value,
              };
            } else {
              mountLoadData[questionResult.questionName] = {
                [questionResult.alternativeId]: questionResult.value,
              };
            }
          } else {
            mountLoadData[questionResult.questionName] = {
              [questionResult.questionId]: questionResult.value,
            };
          }
        } else {
          mountLoadData[questionResult.questionName] = questionResult.value;
        }
      });

      return mountLoadData;
    },
    [],
  );

  useLayoutEffect(() => {
    const favicon2 = document.getElementById('favicon');
    const prefix = process.env.NODE_ENV === 'development' ? 'http' : 'https';

    /// FAVICON
    if (access?.survey?.style && access?.survey.style.urlFavicon) {
      favicon2?.setAttribute('href', access?.survey.style.urlFavicon);
      localStorage.setItem('faviconPath', access?.survey.style.urlFavicon);
    } else {
      favicon2?.setAttribute(
        'href',
        `${prefix}://${window.location.host}/favicon3.ico`,
      );
      localStorage.setItem(
        'faviconPath',
        `${prefix}://${window.location.host}/favicon3.ico`,
      );
    }

    /// BROWSERTITLE
    if (access?.survey?.style && access?.survey.style.browserTitle) {
      document.title = access?.survey.style.browserTitle ?? '...';
    } else {
      document.title = access?.survey.name ?? '...';
    }

    /// BACKGROUNDCLOR
    if (access?.survey?.style && access?.survey.style.overlayBoxColor) {
      setBackGroundColor(`${access?.survey.style.overlayBoxColor}f2`);
    } else {
      setBackGroundColor('rgb(255, 120, 12, 0.9)');
    }

    /// BUTTONCOLOR
    if (access?.survey?.style && access?.survey.style.buttonsColor) {
      setButtonColor(access?.survey.style.buttonsColor);
    } else {
      setButtonColor('#1AB394');
    }

    /// HIDEPROGRESSTEXT
    if (
      access?.survey?.style &&
      access?.survey.style.hideProgressText &&
      access?.survey.style.hideProgressText === true
    ) {
      setHideProgressState(true);
    } else {
      setHideProgressState(false);
    }

    /// HEADERCOLOR
    if (access?.survey?.style && access?.survey.style.headerColor) {
      setHeaderColor(access?.survey.style.headerColor);
    } else {
      setHeaderColor('#e0e0e0');
    }

    /// SIZE LOGO
    if (access?.survey?.style && access?.survey.style.heightLogo) {
      setHeightLogo(access?.survey.style.heightLogo);
    } else {
      setHeightLogo('100%');
    }

    /// HEADER IMAGE
    if (
      access?.survey?.style &&
      access?.survey.style.header &&
      access?.survey.style.header.filePath
    ) {
      setUrlHeaderImage(access?.survey.style?.header.filePath);
    } else {
      setUrlHeaderImage('');
    }

    /// BACKGROUND IMAGE
    if (
      access?.survey?.style &&
      access?.survey.style.background &&
      access?.survey.style.background.filePath
    ) {
      setUrlBackgroundImage(
        access?.survey.style?.background?.filePath?.replaceAll(' ', '%20'),
      );
    } else {
      setUrlBackgroundImage('');
    }

    /// pathRadarChart
    if (access?.survey?.style && access?.survey.style.urlRadarChart) {
      setUrlRadarChart(access?.survey.style.urlRadarChart);
    } else {
      setUrlRadarChart('/static/media/bg-radar-chart-pink.9da4933a.png');
    }
  }, [access?.survey.name, access?.survey.style]);

  // customize the survey with palceholders and pages between matrix rows
  const handleMatrixAndPlaceHolders = useCallback(
    (surveyParam: any, placeholders: any) => {
      const formattedPages: any[] = [];
      const survey = surveyParam;
      if (survey && survey.startText && placeholders) {
        placeholders.forEach((placeholder: any) => {
          const replace = `${placeholder.searchValue}`;
          const regexReplace = new RegExp(replace, 'g');
          survey.startText.text = surveyParam.startText.text.replace(
            regexReplace,
            placeholder.replaceValue,
          );
        });
      }

      // sometimes the system needs return a code to the client. So the user create the final text and we replace it with the code
      if (survey && survey.completedHtml && params.code) {
        const replace = `respBack=XXXXX`;
        const regexReplace = new RegExp(replace, 'g');
        const codeResult = params.code;
        survey.completedHtml = survey.completedHtml.replace(
          regexReplace,
          `respBack=${codeResult}`,
        );
      }

      if (survey && survey.completedHtml && placeholders) {
        placeholders.forEach((placeholder: any) => {
          const replace = `${placeholder.searchValue}`;
          const regexReplace = new RegExp(replace, 'g');
          survey.completedHtml = survey.completedHtml.replace(
            regexReplace,
            placeholder.replaceValue,
          );
        });
      }

      survey.pages.map((page: any) => {
        const customPages: any[] = [];
        let elementsPerPage: any[] = [];
        page.elements.map((element: any, index: number) => {
          if (placeholders) {
            placeholders.forEach((placeholder: any) => {
              if (element.type === 'matrix') {
                element?.rows?.map((x: any) => {
                  const y = x;
                  const replace = `${placeholder.searchValue}`;
                  const regexReplace = new RegExp(replace, 'g');
                  y.text = x.text.replace(
                    regexReplace,
                    placeholder.replaceValue,
                  );
                  return y;
                });

                element?.columns?.map((x: any) => {
                  const y = x;
                  const replace = `${placeholder.searchValue}`;
                  const regexReplace = new RegExp(replace, 'g');
                  y.text = x.text.replace(
                    regexReplace,
                    placeholder.replaceValue,
                  );
                  return y;
                });
              }

              if (element.type === 'html') {
                const htmlPlaceHolder = element;
                const replace = `${placeholder.searchValue}`;
                const regexReplace = new RegExp(replace, 'g');
                htmlPlaceHolder.html = element?.html?.replace(
                  regexReplace,
                  placeholder.replaceValue,
                );
              }

              const questionTitlePlaceHolder = element;
              const replace = `${placeholder.searchValue}`;
              const regexReplace = new RegExp(replace, 'g');
              questionTitlePlaceHolder.title = element?.title?.replace(
                regexReplace,
                placeholder.replaceValue,
              );

              if (
                questionTitlePlaceHolder &&
                questionTitlePlaceHolder?.choices
              ) {
                questionTitlePlaceHolder?.choices?.map((x: any) => {
                  const y = x;
                  const optionTextPalceHolder: string = y?.text;
                  y.text = optionTextPalceHolder?.replace(
                    regexReplace,
                    placeholder.replaceValue,
                  );

                  return y;
                });
              }
            });
          }

          if (element.type !== 'matrix') {
            if (customPages.length === 0) {
              elementsPerPage.push(element);
              return element; // next loop
            }
            customPages[customPages.length - 1].elements.push(element);
            return element; // next loop
          }

          if (
            element.type === 'matrix' &&
            element?.splitMatrix &&
            element?.numberOfRowsToSplit < element?.rows?.length
          ) {
            const splittedMatrix = getSplittedMatrix(element);

            if (element.numberOfGroupsToInsertPageBreakAfter > 0) {
              let countAux = 1;
              for (let i = 0; i < splittedMatrix.length; i += 1) {
                // push matrixes into newPages
                if (
                  countAux === element.numberOfGroupsToInsertPageBreakAfter &&
                  i !== splittedMatrix.length - 1
                ) {
                  elementsPerPage.push({ ...splittedMatrix[i] });

                  // conditions to generate a new page
                  // if matrix it's the first element on page or if it's the first group of a matrix
                  // and a previous page does NOT exists, then create a new page
                  if (
                    index === 0 ||
                    (element.numberOfGroupsToInsertPageBreakAfter - i - 1 ===
                      0 &&
                      customPages.length === 0)
                  ) {
                    customPages.push({
                      name: `page${i}_${element.matrixId}`,
                      elements: elementsPerPage,
                    });
                  } else {
                    // if it's NOT the first group of a matrix or if matrix it's NOT the first element on page
                    // a new page will not be created, just add into the last page created
                    customPages[customPages.length - 1].elements.push(
                      ...elementsPerPage,
                    );
                  }
                  countAux = 1;
                  elementsPerPage = [];
                } else if (i === splittedMatrix.length - 1) {
                  // create a new page for the last group of the matrix
                  elementsPerPage.push({ ...splittedMatrix[i] });

                  customPages.push({
                    name: `page${i}_${element.matrixId}`,
                    elements: elementsPerPage,
                  });

                  elementsPerPage = [];
                } else {
                  elementsPerPage.push({ ...splittedMatrix[i] });
                  countAux += 1;
                }
              }
              // end of matrix pages - goto next element
              return element; // next loop
            }

            // the matrix was splitted in groups but all groups will be added in the same page
            elementsPerPage.push(...splittedMatrix);
            return element; // next loop
          }

          // it's a matrix, but the user doesn't want to split it
          // the entire element will be added to the survey in the original form
          elementsPerPage.push(element);
          return element; // next loop
        });

        if (customPages.length > 0) {
          formattedPages.push(...customPages);
        } else {
          formattedPages.push({ ...page, elements: elementsPerPage }); // when the page has only simpleElments (customPages.length ===0)
        }

        const newPage = { ...page, elements: [] }; // return fake page just to return something
        return newPage;
      });

      // remove ids from the question's names
      const formattedPagesWithoudElementIds = formattedPages.map(
        (page: any) => {
          return {
            ...page,
            elements: getElementsWithoutId(page.elements),
          };
        },
      );

      return formattedPagesWithoudElementIds;
    },
    [params.code],
  );

  useEffect(() => {
    let idLanguageParameter = 1;
    if (languageId && languageId > 0) {
      idLanguageParameter = languageId;
    }
    api.get(`languages/${idLanguageParameter}`).then(response => {
      if (response.data) {
        if (response.data?.nameButtonStartText) {
          setButtonFortfahrenName(response.data?.nameButtonStartText);
        }

        if (response.data.textLinkFootnote) {
          setButtonMehrInformation(response.data?.textLinkFootnote);
        }

        if (response.data.textFootnote) {
          setFootnoteGDPR(response.data?.textFootnote);
        }

        if (response.data?.contactUsMessage) {
          contactUsMessage = response.data?.contactUsMessage;
        }

        const warnings: MessageWarnings = {
          invalidCode:
            response.data?.codeNotExistsMessage ??
            'Dieser Teilnahmecode ist ungültig.',
          completedSurvey:
            response.data?.surveyCompletedMessage ??
            'Sie haben bereits an der Umfrage teilgenommen. Vielen Dank für Ihre Teilnahme.',
          lockedAccess:
            response.data?.accessLockedMessage ??
            'Diese Umfrage ist nicht mehr aktiv.',
          invalidUrl:
            response.data?.accessLockedMessage ??
            'Diese Umfrage ist nicht mehr aktiv.',
        };

        setWarningMessages(warnings);
      }
    });
  }, [languageId]);

  useEffect(() => {
    if (gdprId > 0) {
      api.get(`gdpr/${gdprId}`).then(response => {
        if (response.data) {
          if (response.data.defaultLanguageId === languageId) {
            if (response.data.textLinkFootnote) {
              setButtonMehrInformation(response.data.textLinkFootnote);
            }

            if (response.data.textFootnote) {
              setFootnoteGDPR(response.data.textFootnote);
            }
          } else if (response.data.translations) {
            const translationsGdpr = response.data.translations.find(
              (x: any) => x.languageId === languageId,
            );
            if (
              translationsGdpr?.textLinkFootnote &&
              translationsGdpr?.textLinkFootnote !== ''
            ) {
              setButtonMehrInformation(translationsGdpr.textLinkFootnote);
            }
            if (
              translationsGdpr?.textFootnote &&
              translationsGdpr?.textFootnote !== ''
            ) {
              setFootnoteGDPR(translationsGdpr?.textFootnote);
            }
          }
        }
      });
    }
  }, [gdprId, languageId]);

  useEffect(() => {
    let mounted = true;
    let accessData: any = {};

    const languageIdUrl = Number(query.get('lang'));
    const languageCodeUrl = query.get('langCode');
    setLanguageCode(languageCodeUrl ?? 'de');

    if (languageIdUrl === 0) {
      setShowSelectLanguageFirst(true);
    } else {
      setShowSelectLanguageFirst(false);
    }

    let originalSurveyId = 0;

    const getUrlValidation = async (): Promise<boolean> => {
      // localhost:3000//questionnaire/6304810f1c853ce51d7350d6/136

      const responseValidation = await api.post(`/accesses/validation`, {
        accessObjectId: params.accessObjectId, // '6304810f1c853ce51d7350d6',
        accessId: params.accessId, // 136,
      });

      if (responseValidation.data) {
        return true;
      }
      return false;
    };

    // get the whole data to show on the screen
    const fetchAccessData = async (): Promise<void> => {
      // fetch access
      const responseAccess = await api.get(`/accesses/${params.accessId}/full`);
      let surveyAccess = responseAccess.data?.survey;
      if (mounted) {
        setReturnedSurveyId(responseAccess.data.survey?.surveyId);
        originalSurveyId = responseAccess.data.survey?.surveyId;

        if (languageIdUrl === 0) {
          setLanguageId(responseAccess.data.survey.defaultLanguageId);
        } else {
          setLanguageId(languageIdUrl);
        }

        if (languageIdUrl && languageIdUrl > 0) {
          if (
            surveyAccess?.languages?.some(
              (language: number) => language === languageId,
            )
          ) {
            const response = await api.get(
              `surveyTranslations/translation/${languageIdUrl}/${originalSurveyId}`,
            );

            let startText: any;
            let startTextByLanguage = '';

            let finalText: any;
            let finalTextByLanguage = '';

            if (response.data.translation?.startTextId) {
              startText = await api.get(
                `surveyTexts/${response.data.translation?.startTextId}`,
              );

              if (startText) {
                const startTextByLanguageTranslated =
                  startText?.data?.translations?.find(
                    (translation: any) =>
                      translation.languageId == languageIdUrl,
                  );

                startTextByLanguage = startTextByLanguageTranslated
                  ? startTextByLanguageTranslated?.text
                  : startText.data?.text;
              }
            }

            if (
              response.data.translation &&
              response.data.translation?.finalTextId
            ) {
              finalText = await api.get(
                `surveyTexts/${response.data.translation?.finalTextId}`,
              );

              if (finalText) {
                const finalTextByLanguageTranslated =
                  finalText?.data?.translations?.find(
                    (translation: any) =>
                      translation.languageId == languageIdUrl,
                  );

                finalTextByLanguage = finalTextByLanguageTranslated
                  ? finalTextByLanguageTranslated.text
                  : finalText.data.text;
              }
            }

            surveyAccess = {
              ...response.data?.translation,
              completedHtml:
                finalTextByLanguage && finalTextByLanguage != ''
                  ? finalTextByLanguage
                  : response.data?.completedHtml,
              startText: {
                text: startTextByLanguage ?? '',
              },

              logo: response.data?.logo ?? response.data?.translation?.logo,
            };
          }
        }

        // format survey elements with placeholders and matrixes pages
        const formattedPages = handleMatrixAndPlaceHolders(
          surveyAccess,
          responseAccess.data?.placeholders,
        );

        const survey = {
          ...surveyAccess,
          idSurvey:
            surveyAccess.surveyId ?? surveyAccess.idSurvey ?? originalSurveyId,
          pages: formattedPages,
          triggers: surveyAccess.triggers,
        };

        delete survey.surveyId;

        const questionnaire = { ...responseAccess.data, survey };

        if (questionnaire.isLocked) setAlertMessage('lockedAccess');
        accessData = questionnaire;

        setGdprId(surveyAccess.gdprId ?? -1);
        setAccess(accessData);
      }
    };

    if (mounted) {
      // get validation accessId and acessObjectId
      getUrlValidation().then(response => {
        if (response) {
          fetchAccessData();
        } else {
          setAlertMessage('lockedAccess');
        }
      });
    }

    return () => {
      mounted = false;
    };
  }, [
    handleMatrixAndPlaceHolders,
    languageId,
    params.accessId,
    params.accessObjectId,
    query,
  ]);

  // Checks if the code is being passed by parameter and, if the code exists, retrieves the results
  useEffect(() => {
    const resultsFromStorage = JSON.parse(
      localStorage.getItem(localStorageName) ?? '{}',
    );

    if (params.code) {
      api.get(`/results/${params.code}`).then(response => {
        if (response.data) {
          const modifiedStoredResults = modifyStoredResults(
            response.data.questionResults,
          );

          localStorage.setItem(
            localStorageName,
            JSON.stringify({
              resultId: response.data.resultId,
              code: response.data.code,
              currentPageNo: response.data.currentPageNo,
              data: modifiedStoredResults,
              isCompleted: response.data.isCompleted,
            }),
          );

          const resultsFromStorageTest = JSON.parse(
            localStorage.getItem(localStorageName) ?? '{}',
          );

          setCurrentPageNo(response.data.currentPageNo);
          setLoadData(resultsFromStorageTest.data);

          if (response.data.isCompleted) setAlertMessage('completedSurvey');
        } else setAlertMessage('invalidCode');
      });
    }
    // If access has not been made by a code, load the survey from the local storage
    else {
      setCurrentPageNo(resultsFromStorage.currentPageNo);
      setLoadData(resultsFromStorage.data);

      if (resultsFromStorage.isCompleted) setAlertMessage('completedSurvey');
    }
  }, [params.code, modifyStoredResults, localStorageName]);

  const handleStartSurvey = useCallback(() => {
    const test = document.getElementById('chkbox');
    const textToAlert = document.getElementById('textToAlert');

    if (test) {
      if ($('#chkbox').is(':checked')) {
        setSurveyVisible(true);
      } else {
        // eslint-disable-next-line no-alert
        alert(textToAlert?.innerText);
      }
    } else {
      setSurveyVisible(true);
    }
  }, []);

  const saveResult = useCallback(
    async (surveyResult: any, isCompleted: boolean): Promise<any> => {
      const result: Result = {
        accessId: access?.accessId,
        userGroup: access?.survey?.subGroup ?? 'customers',
        surveyId: access?.survey.idSurvey,
        testMode: query.get('test') === 'true',
        questionResults: [],
        currentPageNo: surveyResult.currentPageNo + 1,
        isCompleted,
        languageId,
      };

      const resultsFromStorage = JSON.parse(
        localStorage.getItem(localStorageName) ?? '{}',
      );

      result.questionResults = await modifySurveyResults(surveyResult);

      if (resultsFromStorage.resultId > 0) {
        await api.put(`/results/${resultsFromStorage.resultId}`, result);
        return {
          resultId: resultsFromStorage.resultId,
          code: resultsFromStorage.code,
        };
      }

      const resultSaved = await api.post('/results', result);

      return {
        resultId: resultSaved.data.resultId,
        code: resultSaved.data.code,
      };
    },
    [
      access?.accessId,
      access?.survey.idSurvey,
      access?.survey?.subGroup,
      languageId,
      localStorageName,
      modifySurveyResults,
      query,
    ],
  );

  const handlePartialSend = useCallback(
    async (surveyResult: any) => {
      try {
        setCurrentPageAnswering(count => count + 1);
        window.scrollTo(0, 0);
        if (!access?.survey.isTemplate) {
          const { resultId, code } = await saveResult(surveyResult, false);

          setReturnedCode(code);
          localStorage.setItem(
            localStorageName,
            JSON.stringify({
              resultId,
              code,
              currentPageNo: surveyResult.currentPageNo,
              data: surveyResult.data,
              isCompleted: false,
            }),
          );
        }
      } catch (error) {
        console.log('Error: ', error);
        const localStorageData = JSON.parse(
          localStorage.getItem(localStorageName) ?? '{}',
        );
        api.post(`/results/exceptionUpdate`, {
          error,
          code: params.code ?? 'default link',
          access: access?.accessId,
          resultData: surveyResult?.data,
          localStorageData,
        });
        addToast({
          type: 'error',
          title: 'Error Saving',
          description:
            'An error occurred while saving the results, please try again.',
        });
      }
    },
    [
      access?.survey.isTemplate,
      access?.accessId,
      saveResult,
      localStorageName,
      params.code,
      addToast,
    ],
  );

  const handleComplete = useCallback(
    async (surveyResult: any) => {
      try {
        if (!access?.survey.isTemplate) {
          const { resultId, code } = await saveResult(surveyResult, true);

          if (access?.survey.style?.showConfettiRain === true)
            canvasConfetti({
              particleCount: 300,
              spread: 125,
            });

          setReturnedCode(code);

          localStorage.removeItem(localStorageName);

          setShowFinalMessage(true);

          if (query.get('chart') && resultId > 0) {
            setShowOverlay(false);
            setShowFinalMessage(false);
            setShowChart(query.get('chart'));
            setReturnedResultId(resultId);
          }

          if (query.get('radar') && resultId > 0) {
            setShowOverlay(true);
            setShowFinalMessage(false);
            setShowChart(EnumChartType.Radar);
            setReturnedResultId(resultId);
          }
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Error Saving',
          description:
            'An error occurred while saving the results, please try again.',
        });
      }
    },
    [
      access?.survey.isTemplate,
      access?.survey.style?.showConfettiRain,
      saveResult,
      localStorageName,
      query,
      addToast,
    ],
  );

  return (
    <RootDivFonts urlBackground={urlBackgroundImage}>
      {urlHeaderImage !== '' ? (
        <HeaderImage
          useStyle={true}
          headerColor={headerColor}
          sizeLogo={heightLogo}
          showTitle={access?.survey?.showTitle}
        >
          <img
            className="backgroundHeader"
            src={urlHeaderImage ?? ''}
            alt="Logo - Survey"
          />{' '}
          <div>
            <img
              src={access?.survey.logo?.filePath ?? ''}
              alt="Logo - Survey"
            />
          </div>
        </HeaderImage>
      ) : (
        <Header
          useStyle={true}
          headerColor={headerColor}
          sizeLogo={heightLogo}
          showTitle={access?.survey?.showTitle}
        >
          {' '}
          <img src={access?.survey.logo?.filePath ?? ''} alt="Logo - Survey" />
        </Header>
      )}
      {access?.survey?.languages?.length > 0 && showSelectLanguageFirst ? (
        <SelectLanguage survey={access?.survey} />
      ) : (
        <Container
          useStyle={true}
          mainColor={buttonColor}
          showFinalMessage={showFinalMessage}
          hideProgressText={hideProgressText}
        >
          {alertMessage === '' ? (
            access?.survey.startText?.text &&
            !surveyVisible &&
            (!currentPageNo || currentPageNo === 0) ? (
              <StartPage
                text={access?.survey.startText.text}
                onStartButtonClick={handleStartSurvey}
                logoPath={access?.survey.logo?.filePath ?? ''}
                surveyName={access?.survey.title}
                buttonColor={buttonColor}
                headerColor={headerColor}
                languageId={languageId ?? -1}
                languageCode={languageCode}
                gdprId={access?.survey?.gdprId ?? -1}
                nameButtonNext={buttonFortfahrenName}
                nameMehrInformation={buttonMehrInformation}
                textFootnoteGDPR={footnoteGDPR}
              />
            ) : (
              <SurveyPage
                model={access?.survey}
                languageId={languageId}
                onPartialSend={handlePartialSend}
                onComplete={handleComplete}
                segments={access?.segments}
                currentPageNo={currentPageNo}
                data={loadData}
                code={params.code}
                accessId={access?.accessId}
              />
            )
          ) : (
            <AlertMessage>
              <FiAlertTriangle size={40} />
              <br />
              {getWarningsMessage(alertMessage)}
              <p>{contactUsMessage}</p>
            </AlertMessage>
          )}

          {showChart && (
            <div id="chartArea">
              {showChart === EnumChartType.BubbleTree && (
                <div style={{ margin: 10, padding: 10 }}>
                  {/* Captura a imagem assim que renderizar */}
                  <HiddenBubbleTree>
                    <div ref={bubbleTreeRef}>
                      <BubbleTreeContainer
                        surveyId={returnedSurveyId}
                        resultId={returnedResultId}
                      />
                    </div>
                  </HiddenBubbleTree>
                  <TsoSelbtestResults
                    resultId={returnedResultId}
                    code={returnedCode}
                    imageBase64={bubbleTreeImage}
                    captureBubbleTreeImage={captureBubbleTreeImage}
                  />

                  {/* <BubbleTreeContainer
                    surveyId={returnedSurveyId}
                    resultId={returnedResultId}
                  /> */}
                </div>
              )}

              {showChart === EnumChartType.Radar && (
                <RadarContainer
                  surveyId={returnedSurveyId}
                  resultId={returnedResultId}
                  // Todo: create a page to associate the radar background for a specific a-pillar, today is hard coded
                  pinkBackground={surveysSpiritHoch3List.some(
                    surveyId => surveyId === access?.survey.idSurvey,
                  )}
                  // urlRadarChart={'/static/media/bg-radar-chart-pink.9da4933a.png'}
                />
              )}

              {showOverlay && (
                <OverlayBox
                  resultId={returnedResultId}
                  code={returnedCode}
                  setShowOverlay={setShowOverlay}
                  setChartImage={captureBubbleTreeImage}
                  htmlIdToScroll={'chartArea'}
                  {...{ backgroundColor: `${backgroundColor}` }}
                />
              )}
            </div>
          )}
        </Container>
      )}
      <Footer />
    </RootDivFonts>
  );
};

export default Survey;
