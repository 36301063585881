/* eslint-disable prefer-destructuring */
/* eslint-disable eqeqeq */
/* eslint-disable no-case-declarations */

import api from '../services/api';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const getTranslatedSurvey = async (
  surveyEncoded: any,
  questionWithTranslations: any[],
  languageId: number,
  surveyTranslation?: any,
  data?: any,
): Promise<any> => {
  const responseLanguage = await api.get(`languages/${languageId}`);

  const placeHolderTexQuestion =
    responseLanguage.data?.placeholderTextQuestion ??
    'Tippen Sie Ihre Antwort bitte hier ein:';

  const pagesTranslated = await surveyEncoded.pages.map((page: any) => {
    let pageTranslated: any = {};
    const elementsTranslated = page.elements.map((element: any) => {
      let questionWithTranslation: any = {};
      let elementTranslated: any = {};
      let questionTranslated: any = {};
      let rowsTranslated: any = {};
      let columnsTranslated: any = {};
      let elementsFlat: any[] = [];

      let translation: any = {};
      let questionIdToSearch = '';
      let alternativeId = '';
      let questionIdSplitted: string[];
      let alternativeText = '';
      let strQuyestionId = '';
      let matrixElement: any = {};
      // create element translated for each element of the survey
      switch (element.type) {
        case 'dropdown':
        case 'ranking':
        case 'radiogroup':
        case 'barrating':
        case 'ebemotionsratings':
        case 'funnyemotionsratings':
        case 'rating':
        case 'ebslider':
        case 'ebsliderpolarity':
        case 'ebverticalpolarity':
        case 'comment':
        case 'text':
        case 'checkbox':
        case 'ebdragndrop':
          translation = {};
          questionIdToSearch = '';
          alternativeId = '';
          questionIdSplitted = [];
          alternativeText = '';
          strQuyestionId = '';
          strQuyestionId = element.questionId.toString();

          if (strQuyestionId.toString().includes('.')) {
            questionIdToSearch = strQuyestionId.replace('.', '_');
          } else {
            questionIdToSearch = strQuyestionId;
          }

          const questionIdFormData = Object.keys(data).find(
            (key: any) => key == questionIdToSearch,
          );

          let questionDescription = '';

          if (strQuyestionId == element.questionId) {
            questionDescription = data[`${questionIdFormData}`];
          }

          // gets all questions with translations from questions collection
          // and filter the current one
          questionWithTranslation = questionWithTranslations.find(
            (question: any) => question.questionId == strQuyestionId,
          );

          // gets the alternative text translated
          if (strQuyestionId.toString().includes('.')) {
            questionIdSplitted = strQuyestionId.split('.');
            questionIdToSearch = questionIdSplitted[0];
            alternativeId = questionIdSplitted[1];

            questionWithTranslation = questionWithTranslations.find(
              (question: any) => question.questionId == questionIdToSearch,
            );

            translation = questionWithTranslation.translations.find(
              (questionTranslation: any) =>
                questionTranslation.languageId === languageId,
            );

            const alternativeTextFound = translation?.alternatives?.find(
              (alternative: any) => alternative.value == alternativeId,
            );

            alternativeText = alternativeTextFound
              ? alternativeTextFound.text
              : questionWithTranslation.text;
          }

          if (
            questionWithTranslation &&
            questionWithTranslation?.translations
          ) {
            translation = questionWithTranslation.translations.find(
              (questionTranslation: any) =>
                questionTranslation.languageId === languageId,
            );
          }

          elementTranslated = {
            ...element,
            description: questionDescription || '',
            title:
              alternativeText && alternativeText != ''
                ? alternativeText
                : translation?.text
                ? translation?.text
                : questionWithTranslation?.text,
            choices:
              translation &&
              translation.options &&
              translation.options?.length > 0
                ? translation.options
                : questionWithTranslation?.options,
            rateValues:
              translation &&
              translation.options &&
              translation.options?.length > 0
                ? translation.options
                : questionWithTranslation?.options,
          };

          if (element.type === 'text' || element.type === 'comment') {
            delete elementTranslated.choices;
            delete elementTranslated.rateValues;
          }

          if (element.internalType === 'Text') {
            elementTranslated = {
              ...elementTranslated,
              placeHolder: placeHolderTexQuestion,
            };
          }

          break;
        case 'matrix':
          if (surveyTranslation) {
            elementsFlat = surveyTranslation.translation.pages.flatMap(
              ({ elements }: any) =>
                elements?.flatMap((elementFlat: any) => ({
                  ...elementFlat,
                })),
            );
            // eslint-disable-next-line no-case-declarations
            matrixElement = elementsFlat.find(
              (elementFlat: any) => elementFlat?.name === element?.name,
            );
          } else {
            const regexReplace = new RegExp('[^0-9a-zA-Z]+', 'g');
            const elementNameToFind = element.name.replace(regexReplace, '');

            const propertyFiledName = Object.keys(data).find(
              (key: any) => `${key}` == elementNameToFind,
            );

            matrixElement = {
              ...element,
              title: data[`${propertyFiledName}`]
                ? data[`${propertyFiledName}`]
                : element.title,
            };
          }
          if (matrixElement) {
            rowsTranslated = element.rows.map((row: any) => {
              translation = {};
              questionIdToSearch = '';
              alternativeId = '';
              questionIdSplitted = [];
              alternativeText = '';
              strQuyestionId = '';

              strQuyestionId = row.questionIdMatrix
                ? row.questionIdMatrix?.toString()
                : row.questionId?.toString();
              questionIdToSearch = strQuyestionId;

              questionWithTranslation = questionWithTranslations.find(
                (question: any) => question.questionId == questionIdToSearch,
              );

              if (strQuyestionId.includes(`.`)) {
                questionIdSplitted = strQuyestionId.split('.');
                questionIdToSearch = questionIdSplitted[0];
                alternativeId = questionIdSplitted[1];

                questionWithTranslation = questionWithTranslations?.find(
                  (question: any) => question.questionId == questionIdToSearch,
                );

                translation = questionWithTranslation?.translations?.find(
                  (questionTranslation: any) =>
                    questionTranslation?.languageId === languageId,
                );

                alternativeText = translation?.alternatives?.find(
                  (alternative: any) => alternative?.value == alternativeId,
                )?.text;
              }

              if (questionWithTranslation?.translations) {
                questionTranslated = questionWithTranslation?.translations.find(
                  (questionTranslation: any) =>
                    questionTranslation.languageId === languageId,
                );
                columnsTranslated = questionTranslated
                  ? questionTranslated.options
                  : element.columns;
              }

              const rowTranslated = {
                ...row,
                text:
                  alternativeText && alternativeText != ''
                    ? alternativeText
                    : questionTranslated?.text
                    ? questionTranslated?.text
                    : questionWithTranslation?.text,
              };

              return rowTranslated;
            });
          }
          elementTranslated = {
            ...element,
            title: matrixElement ? matrixElement.title : element.title,
            rows: rowsTranslated.length > 0 ? rowsTranslated : element.rows,
            columns:
              columnsTranslated.length > 0
                ? columnsTranslated
                : element.columns,
          };

          break;
        case 'html':
          let htmlElement: any = {};
          // flatmap em array de pages para fletar os elements

          if (surveyTranslation) {
            elementsFlat = surveyTranslation.translation.pages.flatMap(
              ({ elements }: any) =>
                elements?.flatMap((elementFlat: any) => ({
                  ...elementFlat,
                })),
            );
            // eslint-disable-next-line no-case-declarations
            htmlElement = elementsFlat.find(
              (elementFlat: any) => elementFlat?.name === element?.name,
            );
          } else {
            const propertyFiledName = Object.keys(data).find(
              (key: any) => `${key}` === element.name,
            );
            htmlElement = {
              ...element,
              html: data[`${propertyFiledName}`],
            };
          }

          elementTranslated = {
            ...element,
            html: htmlElement ? htmlElement.html : element.html,
          };

          break;
        default:
          elementTranslated = element;
          break;
      }

      return elementTranslated;
    });
    pageTranslated = { ...page, elements: elementsTranslated };

    return pageTranslated;
  });

  const surveyTranslated = {
    ...surveyEncoded,
    title: data.surveyTitle ?? surveyEncoded.title,
    description: data.surveyDescription ?? surveyEncoded.description,
    pages: pagesTranslated,
  };

  return surveyTranslated;
};
export default getTranslatedSurvey;
