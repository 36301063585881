/* eslint-disable no-multi-assign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useCallback, useEffect, useState } from 'react';
// import $ from 'jquery';
import * as Survey from 'survey-react';
// import * as widgets from 'surveyjs-widgets';

import 'survey-react/modern.css';
import '../SurveyCustomWidgets/styles/eb-contact-form.css';

import '../SurveyCustomWidgets/styles/fontawesome-stars.css';
// import 'emotion-ratings/dist/emotion-ratings.js';

import { EbSlider } from '../SurveyCustomWidgets/EbSlider';
import { EbSliderPolarity } from '../SurveyCustomWidgets/EbSliderPolarity';
import { EbVerticalPolarity } from '../SurveyCustomWidgets/EbVerticalPolarity';
import { EbRating } from '../SurveyCustomWidgets/EbRating';
import { EbRadioGroup } from '../SurveyCustomWidgets/EbRadioGroup';
import { EbStyledRating } from '../SurveyCustomWidgets/EbStyledRating';
import { EbEmoticons } from '../SurveyCustomWidgets/EbEmoticons';
import { EbEmoticons2 } from '../SurveyCustomWidgets/EbEmoticons2';
import { ContentSurvey } from './styles';
import api from '../../../services/api';
import { EbDragDrop } from '../SurveyCustomWidgets/EbDragDrop';

import { EbContactForm } from '../SurveyCustomWidgets/EbContatcForm';

Survey.StylesManager.applyTheme('modern');

Survey.Serializer.addProperty('question', 'questionId:number');
Survey.Serializer.addProperty('question', 'internalType:string');
Survey.Serializer.addProperty('question', 'matrixId:number');
Survey.Serializer.addProperty('question', {
  name: 'orderChoices:dropdown',
  category: 'general',
  choices: ['asc value', 'desc value'],
});
Survey.Serializer.addProperty('radiogroup', {
  name: 'choiceImages:itemvalues',
  category: 'ChoiceImages',
});

// widgets.jquerybarrating(Survey, $);
// widgets.emotionsratings(Survey, $);

// interface Language {
//   languageId: string;
//   name: string;
//   pagePrevText: string;
//   pageNextText: string;
//   completeText: string;
//   progressText: string;
//   requiredErrorText: string;
//   placeholderTextQuestion: string;
//   requiredAllrowsErrorText: string;
// }

interface Props {
  model: any;
  languageId?: number | null;
  onComplete?: Function;
  onValueChanged?: Function;
  onPartialSend?: Function;
  segments?: string[] | undefined;
  currentPageNo?: number;
  data?: object;
  code?: string;
  accessId?: number | undefined;
}

const customCss = {
  matrix: { root: 'matrixSmallFont' },
};

const SurveyPage: React.FC<Props> = props => {
  const [model, setModel] = useState<Survey.SurveyModel>(
    new Survey.SurveyModel(props.model),
  );

  useEffect(() => {
    let mounted = true;
    let response: any = {};

    const idLanguage = props.languageId;

    const fetchLanguageData = async (languageId: number | null | undefined) => {
      let mycustomSurveyStrings = {
        pagePrevText: 'Zurück',
        pageNextText: 'Weiter',
        completeText: 'Abschicken',
        progressText: 'Seite {0} von {1}',
        requiredInAllRowsError: 'Bitte beantworten Sie alle Fragen',
        requiredError: 'Bitte eine Antwort geben',
      };

      if (languageId && languageId !== 0) {
        response = await api.get(`/languages/${languageId}`);
        if (response.data) {
          mycustomSurveyStrings = {
            pagePrevText: response.data.pagePrevText,
            pageNextText: response.data.pageNextText,
            completeText: response.data.completeText,
            progressText: response.data.progressText,
            requiredInAllRowsError: response.data.requiredAllrowsErrorText,
            requiredError: response.data.requiredErrorText,
          };
        }
      }

      Survey.surveyLocalization.locales.eb = mycustomSurveyStrings;

      const localModel = new Survey.SurveyModel(props.model);
      localModel.showPageTitles = false;
      localModel.currentPageNo = props.currentPageNo ? props.currentPageNo : 0;
      localModel.data = props.data;
      localModel.locale = 'eb';

      if (mounted) {
        setModel(localModel);
      }
    };
    fetchLanguageData(idLanguage);
    return () => {
      mounted = false;
    };
  }, [props.currentPageNo, props.data, props.languageId, props.model]);

  const questionWithSegment = useCallback(
    (params: string[]) => {
      // this function works only if the question has the sentence bellow, exactlty as is:
      // "visibleIf": "questionWithSegment('Vienna,Baden,Stock Holders' , 'Non Stock Holders') = true",

      // the segments has to be separated by "," and the second parameter is the actual user's segment
      // if this function return true, the question will be hidden
      if (!params) return false;
      const allowedSurveySegments: string[] = params[0]
        .trim()
        .split(',')
        .map(item => item);

      const userSegments = props.segments;

      const shouldShow =
        allowedSurveySegments &&
        allowedSurveySegments.some(item =>
          userSegments?.some(seg => seg === item),
        );

      return shouldShow;
    },
    [props.segments],
  );

  Survey.FunctionFactory.Instance.register(
    'questionWithSegment',
    questionWithSegment,
  );

  Survey.CustomWidgetCollection.Instance.add(EbRating(Survey), 'rating');
  Survey.CustomWidgetCollection.Instance.add(EbSlider(Survey), 'customtype');
  Survey.CustomWidgetCollection.Instance.add(
    EbSliderPolarity(Survey),
    'customtype',
  );
  Survey.CustomWidgetCollection.Instance.add(
    EbVerticalPolarity(Survey),
    'customtype',
  );
  Survey.CustomWidgetCollection.Instance.add(EbEmoticons(Survey), 'customtype');
  Survey.CustomWidgetCollection.Instance.add(
    EbRadioGroup(Survey),
    'radiogroup',
  );
  Survey.CustomWidgetCollection.Instance.add(
    EbEmoticons2(Survey),
    'customtype',
  );
  Survey.CustomWidgetCollection.Instance.add(
    EbStyledRating(Survey),
    'customtype',
  );
  Survey.CustomWidgetCollection.Instance.add(EbDragDrop(Survey), 'customtype');

  Survey.CustomWidgetCollection.Instance.add(
    EbContactForm(Survey),
    'customtype',
  );

  Survey.CustomWidgetCollection.Instance.add({
    name: 'contactinformation',
    title: 'Contact Information',
    elementsJSON: [
      {
        type: 'text',
        name: 'firstName',
        title: 'First name',
        placeholder: 'Olivia',
      },
      {
        type: 'text',
        name: 'lastName',
        title: 'Last name',
        placeholder: 'Johnson',
      },
      {
        type: 'text',
        name: 'phone',
        title: 'Phone number',
        inputType: 'tel',
        placeholder: '+1 (555) 555-1234',
      },
      {
        type: 'text',
        name: 'email',
        title: 'Email',
        inputType: 'email',
        placeholder: 'name@example.com',
      },
      {
        type: 'text',
        name: 'email',
        title: 'Email',
        inputType: 'email',
        placeholder: 'name@example.com',
      },
    ],
  });

  const setUserDataSendMail = useCallback(
    (params: string[]) => {
      if (params.length < 2) return;

      const origin = params[0];
      const target = params[1];

      const localSurvey = model;
      if (!origin || !origin.length) {
        localSurvey.clearValue(target);
        return;
      }

      const inputTextAnswer = document.getElementsByName('inputUserAnswer');
      inputTextAnswer[0]?.setAttribute('value', origin);

      if (props.code) {
        const inputCode = document.getElementsByName('inputCode');
        inputCode[0].setAttribute('value', props?.code ?? '');
      } else {
        const inputCode = document.getElementsByName('inputCode');
        inputCode[0].setAttribute('value', props?.accessId?.toString() ?? '');
      }
    },
    [model, props?.accessId, props.code],
  );

  Survey.FunctionFactory.Instance.register(
    'setUserDataSendMail',
    setUserDataSendMail,
  );

  return (
    <ContentSurvey>
      <>
        <Survey.Survey
          model={model}
          onComplete={props.onComplete}
          sendResultOnPageNext={true}
          onPartialSend={props.onPartialSend}
          onValueChanged={props.onValueChanged}
          showProgressBar="top"
          {...(window.innerWidth > 900 ? { css: customCss } : {})}
        />
        {/* <button onClick={setOfficialCountryName}>Language</button> */}
      </>
    </ContentSurvey>
  );
};

export default SurveyPage;
