import styled from 'styled-components';

interface TooltipErrorProps {
  backgroundColor?: string;
  left?: string;
  right?: string;
  top?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;

  text-align: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 100%;
  max-width: 400px;
`;

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  text-align: left;
  width: 100%;

  input {
    width: 18px;
    height: 18px;
    accent-color: #47283a;
    cursor: pointer;
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  max-width: 400px;
  position: relative;
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 25px;
  border: 2px solid #b31b1a;
  padding: 10px;
  box-shadow: 0 0.125em 0.3125em rgba(0, 0, 0, 0.3);
`;

export const Input = styled.input`
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  color: #333;
  background: transparent;
  padding: 8px;
  border-radius: 20px;
`;

export const Button = styled.button`
  background-color: #47283a;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 25px;
  cursor: pointer;
  transition: background 0.3s;
  font-size: 16px;
  width: 100%;
  max-width: 250px;

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background: #3a2a32;
  }
`;

export const PrivacyText = styled.p`
  font-size: 12px;
  color: #666;
  text-align: center;
  max-width: 400px;
`;

export const HiddenBubbleTree = styled.div`
  position: absolute;
  left: -9999px;
  top: -9999px;
  width: 800px;
  height: 600px;
  overflow: hidden;
`;

// export const HiddenBubbleTree = styled.div`
//   background: #fff;
// `;

export const TooltipError = styled.span<TooltipErrorProps>`
  position: relative;
  right: ${props => props.right ?? ''};
  left: ${props => props.left ?? ''};
  top: ${props => props.top ?? ''};
  width: max-content;
  background: #3a3732;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;

  transition: opacity 0.4s;

  position: absolute;
  bottom: calc(100% + 12px);

  transform: translateX(-50%);

  color: #ffff;
  border: solid 2px #f00;
  &::before {
    content: '';
    border-style: solid;
    border-color: #3a3732 transparent;
    border-width: 6px 6px 0 6px;
    top: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  li {
    padding: 5px;
  }
`;
