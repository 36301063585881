/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-continue */
/* eslint-disable @typescript-eslint/no-explicit-any */

const getDecodedSurvey = (surveyJsonFromDb: any): any => {
  const surveyToLoad: any = { ...surveyJsonFromDb }; // Clone para evitar mutações diretas

  const uniqueDesignerPage: any = { ...surveyJsonFromDb.pages[0] };

  const surveyPages: any[] = [{ ...uniqueDesignerPage }];
  surveyPages[0].elements = [];

  for (let i = 0; i < surveyJsonFromDb.pages.length; i += 1) {
    const { elements } = surveyJsonFromDb.pages[i];

    for (let j = 0; j < elements.length; j += 1) {
      if (elements[j].matrixId) {
        const updatedRows = elements[j].rows?.map((row: any) => {
          const localMatrixRow = {
            questionIdMatrix: row.questionIdMatrix ?? row.questionId,
            questionId: row.questionIdMatrix ?? row.questionId,
            externalId: row.externalId,
            rowVisibleIf: row.visibleIf,
            text: row.text
              ?.trim()
              .includes(row.questionIdMatrix ?? row.questionId)
              ? `${row.text}`
              : `${row.questionIdMatrix ?? row.questionId} - ${row.text}`,
            value: row.questionId,
            numberOfRowsToSplit: row.numberOfRowsToSplit,
            splitMatrix: row.splitMatrix,
            totalOfRows: row.numberOfRowsToSplit,
            numberOfGroups: row.numberOfGroups,
            numberOfGroupsToInsertPageBreakAfter:
              row.numberOfGroupsToInsertPageBreakAfter,
          };

          return localMatrixRow;
        });

        const updatedElement = {
          ...elements[j],
          rows: updatedRows,
        };

        surveyPages[0].elements.push(updatedElement);
      } else if (elements[j].questionId) {
        const updatedElement = { ...elements[j] };

        updatedElement.title = updatedElement.title
          ?.trim()
          .includes(updatedElement.questionId)
          ? `${updatedElement.title}`
          : `${updatedElement.questionId} - ${updatedElement.title}`;

        if (
          ['Scale', 'SelectOne', 'SelectMultiple'].includes(
            updatedElement.internalType,
          )
        ) {
          updatedElement.description = elements[j].description;

          updatedElement.choices = updatedElement.choices.map((choice: any) => {
            if (isNaN(choice)) {
              return choice;
            }

            return {
              value: choice,
              text: `${choice}‎`,
            };
          });

          updatedElement.rateValues = updatedElement.choices.map(
            (choice: any) => {
              if (isNaN(choice)) {
                return choice;
              }

              return {
                value: choice,
                text: `${choice}‎ `,
              };
            },
          );
        }

        surveyPages[0].elements.push(updatedElement);
      } else {
        surveyPages[0].elements.push(elements[j]);
      }
    }

    if (i !== surveyJsonFromDb.pages.length - 1) {
      surveyPages[0].elements.push({
        type: 'pagebreak',
        name: 'pagebreak',
        title: surveyJsonFromDb.pages[i + 1].title,
      });
    }
  }

  surveyToLoad.pages = [...surveyPages];

  return surveyToLoad;
};

export default getDecodedSurvey;
